/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useRef, useEffect, useState } from "react";
import { db, auth } from "../firebase-config";
import { doc, updateDoc, getDoc } from "firebase/firestore";

export const SideBarMenu = ({ sbm, openUsersPanel, openJukeboxPanel }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [runJukebox, setRunJukebox] = useState(false);
  const menuContainer = useRef(null);

  const handleOutsideClick = (e) => {
    if (menuContainer.current && !menuContainer.current.contains(e.target)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleOutsideClick, true);
    if(!runJukebox){
      setRunJukebox(true);
      openJukeboxPanel(true);
    }
    
  }, []);

  const handleSignOut = async () => {
    //before sign out update the user
    const docRef = doc(db, "users", auth.currentUser.uid);
    const docSnap = await getDoc(docRef);
    const currentChat = { currentChat: "none" };
    if (docSnap.exists()) {
      try {
        await updateDoc(docRef, { ...currentChat });
        sbm("sign out");
      } catch (err) {
        console.log(err);
      }
    }
  };

  return (
    <>
      <div className="flex w-full">
        <div className="flex items-center justify-left  w-full relative thredotmenu-container">
          {/* <div
            className="thredotmenu cursor-pointer text-gray-800"
            onClick={() => setIsOpen(!isOpen)}
          ></div> */}
          <div className="cursor-pointer text-gray-800"
            onClick={() => setIsOpen(!isOpen)}>
            <div className="hamburger-menu"></div>
            <div className="hamburger-menu"></div>
            <div className="hamburger-menu"></div>
          </div>
          {isOpen && (
            <div
              className="bg-white divide-y divide-gray-100 rounded-lg shadow w-44  absolute top-7 right--10 md:top-7 md:right--10 z-40"
              ref={menuContainer}
            >
              <ul
                className="py-2 px-0 text-sm text-gray-700 list-none"
                aria-labelledby="dropdownDefaultButton"
              >
                <li className="px-0">
                <button onClick={() => {
                    setIsOpen(!isOpen); 
                    openJukeboxPanel(true);
                    }} 
                    className="block w-full text-left px-4 py-2 hover:bg-gray-100  muuzmenuitem">
                    Jukebox
                  </button>
                </li>
                <li className="px-0">
                  <button className="block w-full text-left px-4 py-2 hover:bg-gray-100  muuzmenuitem">
                    Settings
                  </button>
                </li>
                <li className="px-0">
                  <button className="block w-full text-left px-4 py-2 hover:bg-gray-100  muuzmenuitem">
                    Earnings
                  </button>
                </li>
                <li className="px-0">
                  <button className="block w-full text-left px-4 py-2 hover:bg-gray-100  muuzmenuitem">
                    Pardna
                  </button>
                </li>
                <li className="px-0">
                  <button onClick={() => {
                    setIsOpen(!isOpen); 
                    openUsersPanel(true);
                    }} 
                    className="block w-full text-left px-4 py-2 hover:bg-gray-100  muuzmenuitem">
                    Search Members
                  </button>
                </li>
                <li className="px-0">
                  <button
                    onClick={() => handleSignOut()}
                    className="block w-full text-left px-4 py-2 hover:bg-gray-100  muuzmenuitem"
                  >
                    Sign out
                  </button>
                </li>
              </ul>
            </div>
          )}
        </div>
      </div>
    </>
  );
};
