import React, { useState, useRef, useEffect } from "react";
import Picker from "emoji-picker-react";
import useWindowDimensions from "../lib/useWindowsDimensions";
import { db, auth } from "../firebase-config";
import {
  doc,
  collection,
  addDoc,
  updateDoc,
  setDoc,
  getDoc,
  serverTimestamp,
  onSnapshot,
  query,
  orderBy,
} from "firebase/firestore";

import { Avatar } from "./Avatar";

//import "../styles/Chat.scss";

export const Chat = ({ chatId, newchatId }) => {
  const chatbase = useRef(null);
  const [messages, setMessages] = useState([]);
  const [avatars, setAvatars] = useState({});
  const [newMessage, setNewMessage] = useState("");
  const [inputFocus, setInputFocus] = useState(false);
  // const { height, width } = useWindowDimensions();
  const [showPicker, setShowPicker] = useState(false);

  const onEmojiClick = (event, emojiObject) => {
    setNewMessage((prevInput) => prevInput + emojiObject.emoji);
    setShowPicker(false);
  };

  useEffect(() => {
    chatbase.current.scrollIntoView({ behavior: "smooth", block: "end" });
  }, [messages]);
  useEffect(() => {
    setNewMessage("");
    setMessages([]);
    setAvatars({});
  }, [newchatId]);
  useEffect(() => {
    const chatsMessgesRef = collection(db, "chats", chatId, "messages");
    const queryMessages = query(chatsMessgesRef, orderBy("createdAt"));
    const unsuscribe = onSnapshot(queryMessages, (snapshot) => {
      let messagesx = [];
      let avatars = [];

      snapshot.forEach((doc) => {
        // create an avatar list that can be referred to when the chatlist is rendered
        avatars.push(doc.data().uid);
        messagesx.push(Object.assign({ ...doc.data(), id: doc.id }));
      });

      new Promise(function (resolve, reject) {
        const avatarObj = {};
        const uniq = [...new Set(avatars)];
        let i = 0;
        // search for these uids and the photoURL that goes with them
        uniq.forEach(async (uid) => {
          i++;
          const docRef = doc(db, "users", uid);
          const docSnap = await getDoc(docRef);
          if (docSnap.exists()) {
            avatarObj[uid] = {
              displayName: docSnap.data().displayName,
              photoURL: docSnap.data().photoURL,
              email: docSnap.data().email,
            };
          } else {
            // some random image maybe an ai generated icon and displayname
            avatarObj[uid] = {
              displayName: docSnap.data().displayName,
              photoURL: docSnap.data().photoURL,
            };
          }
        });
        if (i >= uniq.length) resolve(avatarObj);
        else reject();
      })
        .then(function (avatarObj) {
          return new Promise((resolve, reject) => {
            setTimeout(() => resolve(setAvatars(avatarObj)), 300);
          });
        })
        .then(function (result) {
          setMessages(messagesx);
          setTimeout(() => {
            updateWatchList(messagesx.length - 1);
            // update
          }, 2000);
        });
    });
    return () => unsuscribe();
  }, [chatId]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    setInputFocus(false);
    chatbase.current.scrollIntoView({ behavior: "smooth", block: "end" });

    if (newMessage === "") return;
    const messagesRef4 = collection(db, "chats", chatId, "messages");
    await addDoc(messagesRef4, {
      text: newMessage,
      createdAt: serverTimestamp(),
      user: auth.currentUser.displayName || auth.currentUser.email,
      uid: auth.currentUser.uid,
    });

    setTimeout(async () => {
      updateWatchList(messages.length);

      //now go look for all the other chat users personal chat listand update the updatedAt field
      // so it can be snapped on in ChatList

      const otherUsersRef = doc(
        db,
        "users",
        auth.currentUser.uid,
        "chats",
        chatId
      );
      const otherUsersDoc = await getDoc(otherUsersRef);
      if (otherUsersDoc.exists) {
        const otherUserList = otherUsersDoc.data().users;
        otherUserList.forEach(async (item, i) => {
          try {
            const updatedAt = {
              chatLength: messages.length,
            };
            const otherItemRef = doc(db, "users", item.id, "chats", chatId);
            const otherItem = await getDoc(otherItemRef);
            if (otherItem.exists()) {
              await updateDoc(otherItemRef, { ...updatedAt });
            }
          } catch (err) {
            console.log(err);
          }
        });
      }
    }, 500);

    // also update the user chat so the other user can be informed there are new messages in this chat
    // so, get user list from this users chat then that matches this chatId
    const docRef = doc(db, "users", auth.currentUser.uid);
    const docSnap = await getDoc(docRef);
    const currentChat = { currentChat: chatId };
    if (docSnap.exists()) {
      try {
        await updateDoc(docRef, { ...currentChat });
      } catch (err) {
        console.log(err);
      }
    }

    setNewMessage("");

    setTimeout(() => {
      chatbase.current.scrollIntoView({ behavior: "smooth", block: "end" });
    }, 500);
  };

  const updateWatchList = async (msgsLength) => {
    const updatewatchListRef2 = doc(
      db,
      "users",
      auth.currentUser.uid,
      "updatewatchList",
      chatId
    );

    try {
      const thisChat2 = {
        chatId: chatId,
        updatedAt: serverTimestamp(),
        chatLength: msgsLength,
      };
      const updatewatchListDocSnap = await getDoc(updatewatchListRef2);
      if (updatewatchListDocSnap.exists()) {
        await setDoc(
          doc(db, "users", auth.currentUser.uid, "updatewatchList", chatId),
          thisChat2
        );
      } else {
        await setDoc(
          doc(db, "users", auth.currentUser.uid, "updatewatchList", chatId),
          thisChat2
        );
      }
    } catch (err) {
      console.log(err);
    }
  };

  let lastID = 0;

  return (
    <>
      <div
        className="flex flex-col flex-auto p-6 relative"
        style={{ height: "calc(100dvh - 86px)" }}
      >
        <div className="flex flex-col flex-auto flex-shrink-0 rounded-2xl bg-gray-100 dark:bg-gray-900 h-full p-4 panel-fade">
          <div
            className="flex flex-col overflow-x-auto mb-4 relative"
            style={{ height: "calc(100dvh - 182px)" }}
          >
            <div className="flex flex-col h-full z-0">
              <div className="grid grid-cols-12 gap-y-2" ref={chatbase}>
                {messages.map((message, index) => {
                  let noshow = true;
                  if (message.uid !== lastID) {
                    noshow = false;
                    lastID = message.uid;
                  }
                  return (
                    <div
                      className={
                        auth.currentUser.displayName === message.user ||
                        auth.currentUser.email === message.user
                          ? "col-start-2 col-end-13 p-3 rounded-lg chatbubble"
                          : "col-start-1 col-end-12 p-3 rounded-lg chatbubble"
                      }
                      key={index}
                    >
                      <div
                        className={
                          auth.currentUser.displayName === message.user ||
                          auth.currentUser.email === message.user
                            ? "flex items-center justify-start flex-row-reverse"
                            : "flex flex-row items-center"
                        }
                      >
                        {!noshow && (
                          <div
                            className={
                              auth.currentUser.displayName === message.user ||
                              auth.currentUser.email === message.user
                                ? "flex items-center justify-center h-14 w-14 rounded-full border overflow-hidden bg-indigo-500 flex-shrink-0  z-10"
                                : "flex items-center justify-center h-14 w-14 rounded-full border overflow-hidden bg-indigo-500 flex-shrink-0  z-10"
                            }
                          >
                            <Avatar avObj={avatars} uid={message.uid} />
                          </div>
                        )}
                        <div
                          className={
                            auth.currentUser.displayName === message.user ||
                            auth.currentUser.email === message.user
                              ? "relative mr-3 text-sm text-gray-800 dark:text-gray-800 bg-indigo-100 py-2 px-4 shadow rounded-l-xl rounded-br-xl z-10"
                              : "relative ml-3 text-sm text-gray-800 dark:text-gray-800 bg-white py-2 px-4 shadow rounded-e-xl rounded-es-xl  z-10"
                          }
                        >
                          <div>
                            {/* {index} : {message.user} : */} {message.text}
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
          <div className="w-full">
              {showPicker && (
                <Picker
                  pickerStyle={{ width: "100%" }}
                  onEmojiClick={onEmojiClick}
                />
              )}
            </div>          
          <div
            className={`flex flex-row items-center h-16 rounded-xl bg-white px-4 text-enter-fit ${
              inputFocus === true ? "text-enter-full" : ""
            }`}
          >

            <div>
              <button
                className="flex items-center justify-center text-gray-400 hover:text-gray-600"
                onClick={() => setShowPicker((val) => !val)}
              >
                <svg
                  className="w-5 h-5"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M15.172 7l-6.586 6.586a2 2 0 102.828 2.828l6.414-6.586a4 4 0 00-5.656-5.656l-6.415 6.585a6 6 0 108.486 8.486L20.5 13"
                  ></path>
                </svg>
              </button>
            </div>
            <div className="flex flex-column flex-grow ml-2 h-16 items-center">
              <div className="relative w-full">
                <input
                  type="text"
                  placeholder="Type a message..."
                  value={newMessage}
                  onFocus={() => setInputFocus(true)}
                  onChange={(event) => setNewMessage(event.target.value)}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") handleSubmit(e);
                  }}
                  className="flex w-full text-gray-800 dark:text-gray-800 border rounded-xl focus:outline-none focus:border-indigo-300 pl-4 h-20"
                />
                <button className="absolute flex items-center justify-center h-full w-12 right-0 top-0 text-gray-400 hover:text-gray-600">
                  <svg
                    className="w-6 h-6"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M14.828 14.828a4 4 0 01-5.656 0M9 10h.01M15 10h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                    ></path>
                  </svg>
                </button>
              </div>
            </div>
            <div className="ml-2">
              <button
                onClick={(event) => handleSubmit(event)}
                className="flex items-center justify-center bg-indigo-500 hover:bg-indigo-600 rounded-xl text-white px-4 py-1 flex-shrink-0 w-8 h-8"
              >
                <span className="ml-0">
                  <svg
                    className="w-4 h-4 transform rotate-45 -mt-px"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M12 19l9 2-9-18-9 18 9-2zm0 0v-8"
                    ></path>
                  </svg>
                </span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
