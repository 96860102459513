import React, { useState, useEffect } from "react";
import { db, auth } from "../firebase-config";
import {
  collection,
  doc,
  addDoc,
  setDoc,
  getDocs,
  getDoc,
  serverTimestamp,
  onSnapshot,
  query,
  orderBy, // leave this to eventually order by screen name
} from "firebase/firestore";
import { Avatar } from "./Avatar";

import "../styles/Chat.scss";

export const Users = () => {
  const [users, setUsers] = useState([]);
  const usersRef = collection(db, "users");
  const [avatars, setAvatars] = useState({});
  const [inputFocus, setInputFocus] = useState(false);
  const [newSearch, setNewSearch] = useState("");

  useEffect(() => {
    const queryMessages = query(
      usersRef /*,
      where("room", "==", room),
      orderBy("createdAt")*/
    );
    const unsuscribe = onSnapshot(queryMessages, (snapshot) => {
      let users = [];
      let avatars = [];
      snapshot.forEach((doc) => {
        avatars.push(doc.id);
        users.push({ ...doc.data(), id: doc.id });
      });
      new Promise((resolve, reject) => {
        const avatarObj = {};
        let i = 0;
        // search for these uids and the photoURL that goes with them
        avatars.forEach(async (uid) => {
          i++;
          const docRef = doc(db, "users", uid);
          const docSnap = await getDoc(docRef);
          if (docSnap.exists()) {
            avatarObj[uid] = {
              displayName: docSnap.data().displayName,
              photoURL: docSnap.data().photoURL,
              email: docSnap.data().email,
            };
          } else {
            // some random image maybe an ai generated icon and displayname
            avatarObj[uid] = {
              displayName: docSnap.data().displayName,
              photoURL: docSnap.data().photoURL,
            };
          }
        });
        if (i >= avatars.length) resolve(avatarObj);
        else reject();
      })
        .then(function (avatarObj) {
          return new Promise((resolve, reject) => {
            setTimeout(() => resolve(setAvatars(avatarObj)), 300);
          });
        })
        .then((newChatlist) => {
          return new Promise((resolve, reject) => {
            setTimeout(() => resolve(setUsers(users)), 300);
          });
        });
    });

    return () => unsuscribe();
  }, []);

  const addToFriendsList = async (e, user) => {
    e.preventDefault();

    const chatRef = collection(db, "users", auth.currentUser.uid, "chats");
    try {
      const { docs } = await getDocs(chatRef);
      let chatFound = null;
      docs.forEach((doc) => {
        const userList = doc.data().users;
        const i = userList.findIndex(
          (chatuser) =>
            chatuser.id === user.id && chatuser.id !== auth.currentUser.uid
        );
        // console.log("i", i);
        if (i !== -1) {
          chatFound = doc.id;
        }
      });
      if (chatFound !== null) {
        // console.log('chat to be opened', chatFound)
      } else {
        // make a new chat featuring this picked user and the current logged in user
        const chatStoreRef = collection(db, "chats");
        const newChatStoreId = await addDoc(chatStoreRef, {
          createdAt: serverTimestamp(),
          type: "private", // or group
        });

        // console.log("newChatStoreId", newChatStoreId);
        const chatRefRef = doc(
          db,
          "users",
          auth.currentUser.uid,
          "chats",
          newChatStoreId.id
        );
        await setDoc(chatRefRef, {
          createdAt: serverTimestamp(),
          type: "private", // or group
          users: [
            {
              id: auth.currentUser.uid || "",
              email: auth.currentUser.email || "",
              displayName: auth.currentUser.displayName || "",
            },
            {
              id: user.id || "",
              email: user.email || "",
              displayName: user.displayName || "",
            },
          ],
          chatId: newChatStoreId.id,
        });

        const chatRefRef2 = doc(
          db,
          "users",
          user.id,
          "chats",
          newChatStoreId.id
        );
        await setDoc(chatRefRef2, {
          createdAt: serverTimestamp(),
          type: "private", // or group
          users: [
            {
              id: user.id || "",
              email: user.email || "",
              displayName: user.displayName || "",
            },
            {
              id: auth.currentUser.uid || "",
              email: auth.currentUser.email || "",
              displayName: auth.currentUser.displayName || "",
            },
          ],
          chatId: newChatStoreId.id,
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setInputFocus(false);
    console.log("sEaRCh UsErS");
  };

  return (
    <>
      <div className="flex flex-col flex-auto h-full p-6">
        <div className="flex flex-col flex-auto flex-shrink-0 rounded-2xl bg-gray-100 dark:bg-gray-900 h-full p-4">
          <div
            className={`flex flex-row items-center h-16 rounded-xl bg-white w-full px-4 mb-4 text-enter-fit hidden ${
              inputFocus === true ? "text-enter-full" : ""
            }`}
          >
            <div>
              <button className="flex items-center justify-center text-gray-400 hover:text-gray-600">
                <svg
                  className="w-5 h-5"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M15.172 7l-6.586 6.586a2 2 0 102.828 2.828l6.414-6.586a4 4 0 00-5.656-5.656l-6.415 6.585a6 6 0 108.486 8.486L20.5 13"
                  ></path>
                </svg>
              </button>
            </div>
            <div className="flex-grow ml-2">
              <div className="relative w-full">
                <input
                  type="text"
                  value={newSearch}
                  onFocus={() => setInputFocus(true)}
                  onChange={(event) => setNewSearch(event.target.value)}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") handleSubmit(e);
                  }}
                  className="flex w-full border rounded-xl focus:outline-none focus:border-indigo-300 pl-4 h-10"
                />
                <button className="absolute flex items-center justify-center h-full w-12 right-0 top-0 text-gray-400 hover:text-gray-600">
                  <svg
                    className="w-6 h-6"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M14.828 14.828a4 4 0 01-5.656 0M9 10h.01M15 10h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                    ></path>
                  </svg>
                </button>
              </div>
            </div>
            <div className="ml-2">
              <button
                onClick={(event) => handleSubmit(event)}
                className="flex items-center justify-center bg-indigo-500 hover:bg-indigo-600 rounded-xl text-white px-4 py-1 flex-shrink-0 w-4 h-8"
              >
                {/* <span>Send</span> */}
                <span className="ml-0">
                  <svg
                    className="w-4 h-4 transform rotate-45 -mt-px"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M12 19l9 2-9-18-9 18 9-2zm0 0v-8"
                    ></path>
                  </svg>
                </span>
              </button>
            </div>
          </div>
          <div className="flex flex-col h-full overflow-x-auto mb-4">
            <div className="flex flex-col h-full">
              <div className="grid grid-cols-12 gap-y-2">
                {users.map((user, i) => (
                  <div
                    className="col-start-1 col-end-13 p-3 rounded-lg"
                    key={user.id}
                  >
                    <div className="flex flex-row items-center">
                      <button
                        className="flex flex-row items-center text-gray-800 dark:text-gray-400  hover:bg-white bg-gray-200 dark:bg-gray-800 rounded-xl p-2 w-full"
                        onClick={(e) => addToFriendsList(e, user)}
                      >
                        <div className="flex items-center justify-center h-8 w-8 rounded-full border overflow-hidden bg-indigo-500 flex-shrink-0">
                          <Avatar avObj={avatars} uid={user.id} />
                        </div>
                        <div className="ml-2 text-sm font-semibold">
                          <div
                            style={{ width: "100px", overflowX: "hidden" }}
                            className="text-left text-ellipsis"
                          >
                            {user.displayName === "" ? (
                              <>{user.email}</>
                            ) : (
                              <>{user.displayName}</>
                            )}
                          </div>
                        </div>
                        <div className="flex items-center justify-center ml-auto text-xs text-white bg-red-500 h-4 w-4 rounded leading-none">
                          *{/* {user.unreadAmount} */}
                        </div>
                      </button>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
