import React, { useState, useRef, useEffect } from "react";

export const Avatar = ({ avObj, uid }) => {
  const [userInfo, setUserInfo] = useState(null);

  useEffect(() => {
    setUserInfo(avObj[uid]);
  }, [avObj, uid]);

  const FallBackAvatar = ({ userInfo }) => {
    // get some rando images and place first letter of either displayName or email
    let capLetter = "";
    // fallback avatar'
    if (
      userInfo &&
      userInfo !== null &&
      (userInfo.displayName !== undefined || userInfo.email !== undefined)
    ) {
      capLetter =
        userInfo.displayName !== undefined
          ? userInfo.displayName.charAt(0) || ""
          : userInfo.email.charAt(0) || "";
    }
    return <div style={{ textTransform: "capitalize" }}>{capLetter}</div>;
  };

  return (
    <div>
      {userInfo && userInfo.photoURL ? (
        <img
          src={userInfo.photoURL}
          alt={
            userInfo.displayName !== undefined
              ? userInfo.displayName.charAt(0)
              : userInfo.email.charAt(0)
          }
          title={userInfo.displayName}
        />
      ) : (
        <FallBackAvatar userInfo={userInfo} />
      )}
    </div>
  );
};
