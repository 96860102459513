import React, { useEffect, useState } from "react";
import { auth } from "../firebase-config";
import { signOut } from "firebase/auth";
import //collection,
// addDoc,
// where,
// serverTimestamp,
// onSnapshot,
// query,
// orderBy,
"firebase/firestore";

import { Jukebox } from "./Jukebox";
import { Users } from "./Users";
import { ChatList } from "./ChatList";
import { Chat } from "./Chat";
import { SideBarMenu } from "./SideBarMenu";
import { Avatar } from "./Avatar";
import { ActiveConvos } from "./ActiveConvos";
import Cookies from "universal-cookie";
import { isMobile } from "react-device-detect";


const cookies = new Cookies();

export const AppLayout = ({
  children,
  isAuth,
  setIsAuth,
  setIsInChat,
  setChatId,
}) => {
  const [isInChat2, setIsInChat2] = useState(null);
  const [isInUsers, setIsInUsers] = useState(null);
  const [isInJukebox, setIsInJukebox] = useState(null);

  const [chatId2, setChatId2] = useState(null);
  const [isOpen, setIsopen] = useState(false); // sniff if view is desktop or mobile
  const [avatars, setAvatars] = useState({});

  const [mode, setMode] = useState("light");
  const [minimised, setMinimised] = useState(false);

  // const ToggleSidebar = () => {
  //   isOpen === true ? setIsopen(false) : setIsopen(true);
  //   console.log("isOpen", isOpen);
  // };
  const signUserOut = async () => {
    await signOut(auth);
    cookies.remove("auth-token");
    setIsAuth(false);
    setIsInChat(false);
    setIsInChat2(false);
    setIsInUsers(false);
  };

  const closeAllPanels = () => {
    setIsInChat(false);
    setIsInChat2(false);
    setIsInUsers(false);
    setIsInJukebox(false);
    setIsopen(false);
  };

  const pickedChat = (chatId) => {
    closeAllPanels();
    setIsInChat2(true);
    setChatId(chatId);
    setChatId2(null);
    setChatId2(chatId);
    setIsopen(true);
  };
  const openUsersPanel = () => {
    closeAllPanels();
    setIsopen(true);
    setIsInUsers(true);
  };
  const openJukeboxPanel = () => {
    closeAllPanels();
    setIsInJukebox(true);
  };

  const sbm = (message) => {
    if (message === "sign out") signUserOut();
  };

  useEffect(() => {
    // Add listener to update styles
    console.log("isOpen", isOpen);
    window
      .matchMedia("(prefers-color-scheme: dark)")
      .addEventListener("change", (e) =>
        onSelectMode(e.matches ? "dark" : "light")
      );

    // Setup dark/light mode for the first time
    console.log(
      'window.matchMedia("(prefers-color-scheme: dark)").matches',
      window.matchMedia("(prefers-color-scheme: dark)").matches
    );
    onSelectMode(
      window.matchMedia("(prefers-color-scheme: dark)").matches
        ? "dark"
        : "light"
    );

    // Remove listener
    return () => {
      window
        .matchMedia("(prefers-color-scheme: dark)")
        .removeEventListener("change", () => {});
    };
  }, []);

  useEffect(() => {
    // Add listener to update styles
    console.log("isOpen", isOpen);
  }, [isOpen]);

  const onSelectMode = (mode) => {
    setMode(mode);
    if (mode === "dark") {
      // document.body.classList.add('dark-mode')
      document.documentElement.setAttribute("data-theme", "dark");
      console.log("dark mode");
    } else {
      // document.body.classList.remove('dark-mode')
      document.documentElement.setAttribute("data-theme", "light");
      console.log("light mode");
    }
  };

  useEffect(() => {
    if (isAuth !== undefined) {
      setTimeout(() => {
        const avObj = {};
        avObj[auth?.currentUser?.uid] = {
          displayName:
            auth?.currentUser?.displayName ||
            auth?.currentUser?.email ||
            undefined,
          photoURL: auth?.currentUser?.photoURL || undefined,
        };
        setAvatars(avObj);
      }, 300);
    }
  }, [isAuth]);

  if (!auth.currentUser) {
    <div>LOADING...</div>;
  }

  return (
    <>
      {minimised ? (
        <section id="muuzbox" className={"shadow"}>
          <div className={"close-muuzbox-btn cursor-pointer "}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              x="0px"
              y="0px"
              width="30"
              height="30"
              viewBox="0 0 30 30"
            >
              <path
                d="M 7 4 C 6.744125 4 6.4879687 4.0974687 6.2929688 4.2929688 L 4.2929688 6.2929688 C 3.9019687 6.6839688 3.9019687 7.3170313 4.2929688 7.7070312 L 11.585938 15 L 4.2929688 22.292969 C 3.9019687 22.683969 3.9019687 23.317031 4.2929688 23.707031 L 6.2929688 25.707031 C 6.6839688 26.098031 7.3170313 26.098031 7.7070312 25.707031 L 15 18.414062 L 22.292969 25.707031 C 22.682969 26.098031 23.317031 26.098031 23.707031 25.707031 L 25.707031 23.707031 C 26.098031 23.316031 26.098031 22.682969 25.707031 22.292969 L 18.414062 15 L 25.707031 7.7070312 C 26.098031 7.3170312 26.098031 6.6829688 25.707031 6.2929688 L 23.707031 4.2929688 C 23.316031 3.9019687 22.682969 3.9019687 22.292969 4.2929688 L 15 11.585938 L 7.7070312 4.2929688 C 7.5115312 4.0974687 7.255875 4 7 4 z"
                fill="currentColor"
              ></path>
            </svg>
          </div>
          <div>
            <div
              className="muuzbox-loader-logo fadeIn cursor-pointer"
              onClick={() => setMinimised(!minimised)}
            >
              {/* <!-- Your Logo Here --> */}
              <img
                src="https://firebasestorage.googleapis.com/v0/b/chatbout001.appspot.com/o/users%2FBs7gqBrNUtMBmM7jgEyGmCKtSNl1%2Ffiles%2Fmuuzbox-logo.png?alt=media&token=e0f887ad-e97a-4ebc-97cc-2d4fa0d79b1d"
                alt="Muuzbox logo"
              />
              <div className="muuzbox-loader-blob"></div>
            </div>
            <div className="absolute top-0 left-0  z-10">
              <div className={"flex items-center justify-center "}></div>
            </div>
          </div>
        </section>
      ) : (
        <section
          id="muuzbox-inner"
          class={"shadow " + (isAuth ? "active" : "")}
        >
          <div className="App bg-white">
            <div className="app-container max-w-full">
              {children}
              {isAuth && (
                <>
                  <section className="h-screen overflow-hidden flex items-center justify-center max-w-full">
                    <div className="flex h-screen antialiased text-gray-800 w-full">
                      <div className="flex flex-row h-full w-full overflow-hidden">
                        <div
                          className={`flex flex-col py-8 pl-1 md:pl-6 pr-2 w-7 md:w-64 flex-shrink-0 sidebar ${
                            isOpen === true ? "active" : ""
                          }`}
                        >
                          <div className="flex flex-col md:flex-row items-center justify-center h-12 w-full my-2">
                            <div className="flex flex-row justify-center">
                              <div
                                className={`muuzbox-wc-logo cursor-pointer ${
                                  isOpen === true ? "active" : ""
                                }`}
                                onClick={() => setIsopen(!isOpen)}
                              ></div>
                            </div>

                            {/* <div
                        className={`font-bold text-2xl mobile text-gray-800 ${
                          isOpen === true ? "" : "mobile-w-full"
                        }`}
                        onClick={ToggleSidebar}
                      >
                        {isOpen === true ? (
                          <div> &#10094; </div>
                        ) : (
                          <div> &#10095; </div>
                        )}
                      </div> */}

                            <div
                              className={`ml-2 font-bold notmobile jost-logo-font cursor-pointer whitespace-nowrap ${
                                isOpen === true ? "hidden" : ""
                              }`}
                              onClick={() => setIsopen(!isOpen)}
                            >
                              Muuzbox &#10094;
                            </div>
                            <div
                              className={`ml-2 font-bold notmobile jost-logo-font cursor-pointer ${
                                isOpen === true ? "" : "hidden"
                              }`}
                              onClick={() => setIsopen(!isOpen)}
                            >
                              &#10095;
                            </div>

                            <SideBarMenu
                              openUsersPanel={openUsersPanel}
                              openJukeboxPanel={openJukeboxPanel}
                              sbm={sbm}
                            />
                            {/* <div className={"cursor-pointer"} onClick={() => setMinimised(!minimised)} >
                          --
                        </div> */}
                          </div>

                          <div className="flex flex-col items-center bg-indigo-100 border border-gray-200 mt-4 w-full py-6 px-6 rounded-lg mobile-chatlist panel-fade">
                            {/* <SideBarMenu
                          openUsersPanel={openUsersPanel}
                          openJukeboxPanel={openJukeboxPanel}
                          sbm={sbm}
                        /> */}
                            <div className="flex justify-center h-16 w-16 md:h-20 md:w-20 rounded-full border overflow-hidden text-center items-center ">
                              {isAuth !== undefined && (
                                <Avatar
                                  avObj={avatars}
                                  uid={auth?.currentUser?.uid}
                                />
                              )}
                            </div>
                            <div
                              className={`flex flex-col space-y-1 mt-4 -mx-2 chatlist ebuebu wc-chatlist ${
                                isOpen === true ? "hidden" : ""
                              }`}
                            >
                              <ChatList
                                pickedChat={pickedChat}
                                thinLayout={true}
                              />
                            </div>
                            <div
                              className={`text-sm font-semibold mt-2 menu-open ${
                                isOpen === true ? "" : "menu-fade menu-fade2"
                              }`}
                            >
                              {isAuth !== undefined && (
                                <div>
                                  {auth?.currentUser?.displayName
                                    ? auth?.currentUser?.displayName
                                    : auth?.currentUser?.email}
                                </div>
                              )}
                            </div>
                            <div
                              className={`text-xs text-gray-500 menu-open ${
                                isOpen === true ? "" : "menu-fade menu-fade2"
                              }`}
                            >
                              Muuzbox User
                            </div>
                            <div
                              className={`flex flex-row items-center mt-3 menu-open ${
                                isOpen === true ? "" : "menu-fade menu-fade2"
                              }`}
                            >
                              <div className="flex flex-col justify-center h-4 w-8 bg-indigo-500 rounded-full">
                                <div className="h-3 w-3 bg-white rounded-full self-end mr-1"></div>
                              </div>
                              <div className="leading-none ml-1 text-xs">
                                Active
                              </div>
                            </div>
                          </div>
                          <div
                            className={`flex flex-col mt-8 menu-open ${
                              isOpen === true ? "" : "menu-fade"
                            }`}
                          >
                            <div className="flex flex-row items-center justify-between text-xs">
                              {/* <span className="font-bold">
                            Active Conversations
                          </span> */}

                              <span
                                className={`flex items-center justify-center bg-gray-300 h-4 w-4 rounded-full ${
                                  isOpen === true ? "" : "hidden"
                                }`}
                              >
                                <ActiveConvos uid={auth?.currentUser?.uid} />
                              </span>
                            </div>
                            <div
                              className={`flex flex-col space-y-1 mt-1 -mx-2 h-48 overflow-x-hidden overflow-y-auto wc-chatlist ${
                                isOpen === true ? "" : "hidden"
                              }`}
                            >
                              
                                <ChatList pickedChat={pickedChat} />
                              
                            </div>
                            {/* <div className="flex flex-row items-center justify-between text-xs mt-6">
                          <span className="font-bold">User List</span>
                          <span className="flex items-center justify-center bg-gray-300 h-4 w-4 rounded-full">
                            7
                          </span>
                        </div> */}
                            {/* <div className="flex flex-col space-y-1 mt-4 -mx-2 hidden">
                          <button className="flex flex-row items-center hover:bg-gray-100 rounded-xl p-2">
                            <div className="flex items-center justify-center h-8 w-8 bg-indigo-200 rounded-full">
                              H
                            </div>
                            <div className="ml-2 text-sm font-semibold">
                              Henry Boyd
                            </div>
                          </button>
                        </div> */}
                          </div>
                        </div>
                        {isInChat2 && (
                          <Chat newchatId={chatId2} chatId={chatId2} />
                        )}
                        {isInUsers && <Users />}
                        {/* {isInJukebox && <Jukebox />} */}
                      </div>
                    </div>
                  </section>
                </>
              )}
            </div>
          </div>
          <div
            className={
              "minimise-muuzbox-btn cursor-pointer " + (isAuth ? "" : "hidden")
            }
            onClick={() => setMinimised(!minimised)}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              x="0px"
              y="0px"
              width="30"
              height="30"
              viewBox="0 0 30 30"
            >
              <path
                d="M 7 4 C 6.744125 4 6.4879687 4.0974687 6.2929688 4.2929688 L 4.2929688 6.2929688 C 3.9019687 6.6839688 3.9019687 7.3170313 4.2929688 7.7070312 L 11.585938 15 L 4.2929688 22.292969 C 3.9019687 22.683969 3.9019687 23.317031 4.2929688 23.707031 L 6.2929688 25.707031 C 6.6839688 26.098031 7.3170313 26.098031 7.7070312 25.707031 L 15 18.414062 L 22.292969 25.707031 C 22.682969 26.098031 23.317031 26.098031 23.707031 25.707031 L 25.707031 23.707031 C 26.098031 23.316031 26.098031 22.682969 25.707031 22.292969 L 18.414062 15 L 25.707031 7.7070312 C 26.098031 7.3170312 26.098031 6.6829688 25.707031 6.2929688 L 23.707031 4.2929688 C 23.316031 3.9019687 22.682969 3.9019687 22.292969 4.2929688 L 15 11.585938 L 7.7070312 4.2929688 C 7.5115312 4.0974687 7.255875 4 7 4 z"
                fill="currentColor"
              ></path>
            </svg>
          </div>
        </section>
      )}
    </>
  );
};
