import React, { useState, useEffect } from "react";

import { db } from "../firebase-config";
import {
  collection,
  onSnapshot,
  query,
  // orderBy, // eventualy order by name
} from "firebase/firestore";

export const ActiveConvos = ({ uid }) => {
  const [convos, setConvos] = useState(0);

  useEffect(() => {
    //setTimeout(() => {
      if (uid) {
        const chatsMessgesRef = collection(db, "users", uid, "chats");
        const queryMessages = query(chatsMessgesRef);
        try {
          const unsuscribe = onSnapshot(queryMessages, (snapshot) => {
            // console.log("snapshot.length", snapshot.docs.length);
            setConvos(snapshot.docs.length);
          });
          return () => unsuscribe();
        } catch (err) {
          console.log(err);
        }
      }
    //}, 1000);
  }, [uid]);
  return <div>{convos}</div>;
};
